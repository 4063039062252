export class PricingModel {
  constructor() {
    this.id = null;
    this.bikeTypeId = null;
    this.cardId = null;
    this.rentalPreconditions = {
      minimalBalance: {},
    };
    this.freeMinutesPerDay = null;
    this.priceList = [];
    this.deductions = {
      bikeLeftInZone: {},
    };
    this.penalties = {
      bikeLeftOutOfPreferredZone: {},
      bikeLeftOutOfOperatorZone: {},
    };
    this.cardsPriority = [];
  }
}

export const FareCategory = Object.freeze({
  undetermined: { value: "undetermined", name: "Nieokreślona" },
  payment: { value: "payment", name: "Opłata" },
  sanction: { value: "sanction", name: "Sankcja" },
});

export const PaymentType = Object.freeze({
  rentalTimeBelow12H: {
    value: "rentalTimeBelow12H",
    name: "Opłata za czas przejazdu poniżej 12 godzin",
  },
  bikeLeftOutOfPreferredZonePenalty: {
    value: "bikeLeftOutOfPreferredZonePenalty",
    name: "Opłata za pozostawienie roweru poza preferowaną strefą zwrotu",
  },
  reward: { value: "reward", name: "Bonus za zwrot roweru do strefy" },
  otherPayments: { value: "otherPayments", name: "Inna" },
});

export const CorrectionReasonType = Object.freeze({
  lockProblem: {
    value: "lockProblem",
    name: "Problem z blokadą",
  },
  pauseProblem: {
    value: "pauseProblem",
    name: "Problem z pauzą",
  },
  bikeReturnToZone: {
    value: "bikeReturnToZone",
    name: "Rower zwrócony do strefy",
  },
  aberrationGps: {
    value: "aberrationGps",
    name: "Aberracja GPS",
  },
  rentalCancellation: {
    value: "rentalCancellation",
    name: "Rezygnacja z wypożyczenia",
  },
  bikeLeftNearZone: {
    value: "bikeLeftNearZone",
    name: "Rower pozostawiony przy granicy strefy parkowania",
  },
  bikeLeftNearOperationZone: {
    value: "bikeLeftNearOperationZone",
    name: "Rower pozostawiony przy granicy obszaru operatora",
  },
  bikeDefect: {
    value: "bikeDefect",
    name: "Usterka roweru",
  },
  bonusCorrection: {
    value: "bonusCorrection",
    name: "Korekta bonusu",
  },
  freeRide: {
    value: "freeRide",
    name: "Darmowy przejazd (promocja)",
  },
  systemFailure: {
    value: "systemFailure",
    name: "Awaria systemu",
  },
  other: {
    value: "other",
    name: "Inne",
  },
});

export const SanctionType = Object.freeze({
  rentalTimeOver12H: {
    value: "rentalTimeOver12H",
    name: "Kara za czas przejazdu powyżej 12 godzin",
  },
  bikeLeftOutOfOperationsZonePenalty: {
    value: "bikeLeftOutOfOperationsZonePenalty",
    name: "Kara za zakończenie przejazdu poza obszarem funkcjonowania",
  },
  bikeDamage: { value: "bikeDamage", name: "Uszkodzenie roweru" },
  infrastructureDamage: {
    value: "infrastructureDamage",
    name: "Uszkodzenie infrastruktury",
  },
  otherSanction: { value: "otherSanction", name: "Inna" },
});

export const FeeTypes = Object.freeze({
  free: { value: "free", name: "Darmowy przejazd" },
  fixed: { value: "fixed", name: "Stała opłata" },
  perInterval: { value: "perInterval", name: "Naliczanie minutowe" },
});

export const BonusTypes = Object.freeze({
  bikeLeftInZone: {
    value: "bikeLeftInZone",
    name: "Zwrot w preferowanej strefie zwrotu",
  },
});

export const PenaltyTypes = Object.freeze({
  bikeLeftOutOfPreferredZone: {
    value: "bikeLeftInZone",
    name: "Pozostawienie roweru poza preferowaną strefą zwrotu",
  },
  bikeLeftOutOfOperatorZone: {
    value: "bikeLeftInZone",
    name: "Pozostawienie roweru poza obszarem operatora",
  },
});
