import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { submit } from "redux-form";
import numberFormatter from "../../../../utils/numberFormatter";
import FareSanctionAdjustmentForm from "./FareSanctionAdjustmentForm";

const FareSanctionAdjustmentDialog = ({
  open,
  rideDetails,
  onSave,
  onCancel,
  dispatch,
  submitting,
  zones,
}) => (
  <Dialog open={open}>
    <DialogTitle>Korekta</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Wprowadź kwotę o jaką zmianiasz całkowity koszt przejazdu. Kwota te
        zostanie zarejestrowana jako sankcja/kara. Aktualny koszt przejazdu{" "}
        <strong>{numberFormatter.format(rideDetails.finalFare)}</strong> zł.
      </DialogContentText>
      <FareSanctionAdjustmentForm
        zones={zones}
        onSubmit={onSave}
        totalAmount={rideDetails.finalFare}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting}>
        Anuluj
      </Button>
      <Button
        variant="contained"
        onClick={() => dispatch(submit("fareSanctionAdjustment"))}
        disabled={submitting}
        color="primary"
      >
        Zapisz
      </Button>
    </DialogActions>
  </Dialog>
);

FareSanctionAdjustmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rideDetails: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  zones: PropTypes.object.isRequired,
};

export default connect()(FareSanctionAdjustmentDialog);
